


import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";
import { BorrowerDetails } from "@/models/pos/borrower-info.model";
import { DemographicInformation } from "@/models/pos/demographic-info.model";
import { Acknowledgement } from "@/models/pos/acknowledgement.model";
import { Declaration } from "@/models/pos/declaration.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";

@Component
export default class AddNewBorrowerComponent extends Vue {
  @Prop()
  public loanTxnId;

  @Prop()
  public borrowerInfo;

  @Prop()
  public mapping;

  public borrowerData: any = null;
  public newBorrowerData = [];
  public relationMapping: any = null;

  public getName(data) {
    if (data) {
      let firstName = data.personalInfo.name.firstName;
      let lastName = data.personalInfo.name.lastName;
      lastName = lastName ? lastName : "";
      return firstName + " " + lastName;
    }
  }

  public addBorrower() {
    let borrowerDetails = new BorrowerDetails();
    this.newBorrowerData.push({
      ...borrowerDetails,
      duplicateEmail: false,
    });
  }
  /*************************************************************************************************************************************************************
   *            this function return borrower's list that have no co-borrower
   *************************************************************************************************************************************************************/
  public borrowerList() {
    try {
      let borrowers = [];
      this.relationMapping.forEach(el => {
        if (el.coBorrowerIndex.length == 0) {
          let name =
            this.borrowerData[el.borrowerIndex].personalInfo.name.firstName +
            " " +
            this.borrowerData[el.borrowerIndex].personalInfo.name.lastName;
          borrowers.push({
            name: name,
            index: el.borrowerIndex,
            jointIndex: this.borrowerData[el.borrowerIndex].personalInfo
              .jointWithCoBorrowerIndex
          });
        }
      });
      this.newBorrowerData.forEach((el, i) => {
        if (
          el.personalInfo.relationWithBorrower != "Spouse" &&
          el.personalInfo.name.firstName &&
          el.personalInfo.name.firstName != ""
        ) {
          let name = el.personalInfo.name.lastName
            ? el.personalInfo.name.firstName +
              " " +
              el.personalInfo.name.lastName
            : el.personalInfo.name.firstName;
          borrowers.push({
            name: name,
            index: this.borrowerInfo.length + i,
            jointIndex: el.personalInfo.jointWithCoBorrowerIndex
          });
        }
      });
      return borrowers;
    } catch (error) {
      console.log(error);
    }
  }
  /**********************************************************************************************************************************
   *          In this function unlink existing borrower's relation with co-borrower's                                               *
   *          And make this co-borrower as a borrower                                                                               *
   **********************************************************************************************************************************/
  public unlinkCoBorrower(index) {
    let coBorrowerIndex = this.mapping[index].coBorrowerIndex[0];
    let borrowerIndex = this.mapping[index].borrowerIndex;
    this.relationMapping.push({
      borrowerIndex: coBorrowerIndex,
      coBorrowerIndex: []
    });
    this.relationMapping[index].coBorrowerIndex = [];
    this.borrowerData[borrowerIndex].personalInfo.hasAdditionalBorrower ="false";
    this.borrowerData[borrowerIndex].personalInfo.jointWithCoBorrowerIndex = null;
    this.borrowerData[borrowerIndex].personalInfo.maritalStatus.status = null;
    this.borrowerData[coBorrowerIndex].personalInfo.jointWithCoBorrowerIndex = null;
    this.borrowerData[coBorrowerIndex].personalInfo.maritalStatus.status = null;
  }

  public showRelationDropdown(index) {
    this.borrowerData[
      this.relationMapping[index].coBorrowerIndex[0]
    ].personalInfo.relationWithBorrower = "Spouse";
    let tag: any = document.getElementById("relation" + index);
    tag.style.display == "none"
      ? (tag.style.display = "block")
      : (tag.style.display = "none");
  }
  /*************************************************************************************************************************************************************
   *                                                  Link borrower's with new co-borrower's                                                                *
   *************************************************************************************************************************************************************/
  public createRelationWithBorrower(index) {
    try {
      let borrowerIndex = this.newBorrowerData[index].personalInfo
        .jointWithCoBorrowerIndex;
      let coBorrowerIndex = index + this.borrowerInfo.length;
      let maritalStatus = {
        name: "Married",
        ediValue: "M",
        mismoValue: "Married",
        value: "Married"
      };
      let origin;
      if (borrowerIndex < this.borrowerInfo.length) {
        origin = "borrowerData";
      } else {
        origin = "newBorrowerData";
        borrowerIndex = borrowerIndex - this.borrowerInfo.length;
      }
      this.reSetBorrowerRelation(coBorrowerIndex);
      this[origin][
        borrowerIndex
      ].personalInfo.maritalStatus.status = maritalStatus;
      this[origin][borrowerIndex].personalInfo.hasAdditionalBorrower = "true";
      this[origin][
        borrowerIndex
      ].personalInfo.jointWithCoBorrowerIndex = coBorrowerIndex;
      this.newBorrowerData[
        index
      ].personalInfo.maritalStatus.status = maritalStatus;
      this.borrowerList();
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************************************************************************
   *                             In this function change borrower's relation with primaryBorrower's                                                             *
   **************************************************************************************************************************************************************/
  public changeRelation(index) {
    try {
      if (
        this.newBorrowerData[index].personalInfo.relationWithBorrower !=
        "Spouse"
      ) {
        this.newBorrowerData[index].personalInfo.maritalStatus.status = null;
        this.newBorrowerData[
          index
        ].personalInfo.jointWithCoBorrowerIndex = null;
        this.reSetBorrowerRelation(index + this.borrowerInfo.length);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**********************************************************************************************************************************
   *                                  this function remove newBorrower's and coBorrower's                                           *
   **********************************************************************************************************************************/
  public removeBorrower(index) {
    try {
      this.newBorrowerData.splice(index, 1);
      this.reSetBorrowerRelation(index + this.borrowerInfo.length);
    } catch (error) {
      console.log(error);
    }
  }
  /**********************************************************************************************************************************
   *        this function remove extra borrower's maritalStatus and relationship with other's                                       *
   **********************************************************************************************************************************/
  public reSetBorrowerRelation(index) {
    try {
      this.borrowerData.forEach(el => {
        if (el.personalInfo.jointWithCoBorrowerIndex == index) {
          el.personalInfo.maritalStatus.status = null;
          el.personalInfo.jointWithCoBorrowerIndex = null;
          el.personalInfo.hasAdditionalBorrower = "false";
        }
      });
      this.newBorrowerData.forEach(el => {
        if (el.personalInfo.jointWithCoBorrowerIndex == index) {
          el.personalInfo.maritalStatus.status = null;
          el.personalInfo.jointWithCoBorrowerIndex = null;
          el.personalInfo.hasAdditionalBorrower = "false";
        }
      });
      this.borrowerList();
    } catch (error) {
      console.log(error);
    }
  }

  /********************************************************************************************************************************
   *                      this function check duplicate email and phone number in newBorrowerData array                           *
   ********************************************************************************************************************************/
  public async checkDuplicate() {
    try {
      this.newBorrowerData.forEach(el => { el.duplicateEmail = false; });
      this.newBorrowerData.forEach((element, index) => {
        this.newBorrowerData.forEach((el, i) => {
          if (index < i) {
            if (
              element.personalInfo.contactInformation.email &&
              element.personalInfo.contactInformation.email ==
                el.personalInfo.contactInformation.email
            )
              el.duplicateEmail = true;
          }
        });
      });
      let duplicate = this.newBorrowerData.find(a => a.duplicateEmail);
      return duplicate ? true : false;
    } catch (error) {
      console.log(error);
    }
  }

  /*********************************************************************************************************************************
   *                        this function add more borrower's                                                                      *
   *                        serverside function => addMoreBorrower in common Manager                                               *
   *********************************************************************************************************************************/
  public async saveNewBorrower() {
    try {
      let formValid = await this.$validator.validateAll();
      let duplicate = await this.checkDuplicate();
      if (!formValid || duplicate) {
        return { status: false };
      }
      let response = await Axios.post(
        BASE_API_URL + "los/addMoreBorrower",
        {
          loanTxnId: this.loanTxnId,
          borrowerData: this.borrowerData,
          newBorrowerData: this.newBorrowerData,
          mapping: this.relationMapping,
          demographicInfo: new DemographicInformation(),
          acknowledgement: new Acknowledgement(),
          declaration: new Declaration()
        });
      if (response.data.duplicate) {
        this.newBorrowerData = response.data.borrowerData;
        return { status: false };
      } else if (response.status == 200) {
        return {
          status: true,
          borrowerInfo: response.data.borrowerInfo,
          borrowerMapping: response.data.mapping
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.relationMapping = JSON.parse(JSON.stringify(this.mapping));
    this.borrowerData = JSON.parse(JSON.stringify(this.borrowerInfo));
  }
}
