


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Name, Address } from "@/models/pos/common.model";
import {
  BorrowerDetails,
  Dependents,
  DependentsDetails,
  DateDuration,
  AddressTypeDetail,
  AppliedService
} from "@/models/pos/borrower-info.model";
import AddressComponent from "@/views/LosAddress.vue";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import { mixins } from "vue-class-component";
import { DemographicDetails } from "@/models/pos/demographic-info.model";
import { FinancialDetails } from "@/models/pos/financial-info.model";
import { DeclarationDetails } from "@/models/pos/declaration.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
import { AckDetail } from "@/models/pos/acknowledgement.model";
import {
  MARITAL_STATUS,
  MARITAL_STATUS_UNMARRIED,
  RESIDENCE_BASIS_TYPE,
  LIABILITIES_EXPENSES,
  OTHER_INCOME_SOURCE,
  MISMO_TAXPAYER_IDENTIFICATION_TYPE
} from "@/constants";
import { PendingQuestionList } from "@/models/common.model";
import { coBorrowerDetail } from "@/models/borrowerData.model";
import addNewBorrower from "@/views/add-more-borrower/AddNewBorrower.vue";
import Axios from "axios";

import { BASE_API_URL } from "@/config";
import { PopoverPlugin } from "bootstrap-vue";

@Component({ components: { AddressComponent, addNewBorrower } })
export default class PersonalInformationComponent extends mixins(
  QuestionnaireMixin
) {
  @Prop()
  public history;
  public maritalStatus = MARITAL_STATUS;
  public maritalStatusUnmarried = MARITAL_STATUS_UNMARRIED
  public residenceType = RESIDENCE_BASIS_TYPE;
  public otherLiabilityExpense = LIABILITIES_EXPENSES;
  public otherIncomeSource = OTHER_INCOME_SOURCE;
  public personalInfo: any = null;
  public dotLoader = false;
  public taxpayerIdentificationType = MISMO_TAXPAYER_IDENTIFICATION_TYPE;

  public initializeArray(type) {
    let initTypes = {
      alternateNames: new Name(),
      dependents: new Dependents(),
      address: new AddressTypeDetail()
    };
    return initTypes[type];
  }
  public havePayAmount = false;
  public haveReceiveAmount = false;

  public get otherIncomeSources(): Array<any> {
    let otherIncmSoucres = [];
    if (this.personalInfo) {
      otherIncmSoucres = this.personalInfo.borrowerInfo[
        this.questionHistory.index
      ].incomeFromOtherSources.incomeSource;
    }
    return otherIncmSoucres;
  }

  public set otherIncomeSources(otherIncmSoucres) {
    this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].incomeFromOtherSources.incomeSource = otherIncmSoucres;
  }

  public get otherLiabilitiesAndExpenses(): Array<any> {
    let otherLiabilitiesAndExpenses = [];
    if (this.personalInfo) {
      otherLiabilitiesAndExpenses = this.personalInfo.borrowerInfo[
        this.questionHistory.index
      ].otherLiabilitiesAndExpenses;
    }
    return otherLiabilitiesAndExpenses;
  }

  public set otherLiabilitiesAndExpenses(libAndExp) {
    this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].otherLiabilitiesAndExpenses = libAndExp;
  }

  public get getReceiveAmount() {
    let amount = this.otherIncomeSources.find(
      receive => receive.name && receive.name.value == "Child Support"
    );
    this.haveReceiveAmount = this.checkPayOrReceive();
    return amount ? amount.monthlyIncome : 0;
  }

  public get getPayAmount() {
    let amount = this.otherLiabilitiesAndExpenses.find(
      pay => pay.name && pay.name.value == "Child Support"
    );
    this.havePayAmount = this.checkPayOrReceive();
    return amount ? amount.monthlyPayment : 0;
  }

  public setPayAmount(amount: number) {
    let index = this.otherLiabilitiesAndExpenses.findIndex(
      otherLibExp =>
        otherLibExp.name && otherLibExp.name.value == "Child Support"
    );
    if (index > -1) {
      this.otherLiabilitiesAndExpenses[index].monthlyPayment = amount;
    } else {
      let object = this.otherLiabilityExpense.find(
        a => a.value == "Child Support"
      );
      let obj = {
        name: object,
        monthlyPayment: amount,
        monthsLeftToPay: 0
      };
      if (this.otherLiabilitiesAndExpenses.length > 0) {
        this.otherLiabilitiesAndExpenses[0] = obj;
      } else {
        this.otherLiabilitiesAndExpenses.push(obj);
      }
    }
    this.otherLiabilitiesAndExpenses = JSON.parse(
      JSON.stringify(this.otherLiabilitiesAndExpenses)
    );
  }

  public setReceiveAmount(amount: number) {
    let index = this.otherIncomeSources.findIndex(
      otherIncSrc =>
        otherIncSrc.name && otherIncSrc.name.value == "Child Support"
    );
    if (index > -1) {
      this.otherIncomeSources[index].monthlyIncome = amount;
    } else {
      let object = this.otherIncomeSource.find(a => a.value == "Child Support");
      let obj = {
        name: object,
        monthlyIncome: amount
      };
      if (this.otherIncomeSources.length > 0) {
        this.otherIncomeSources[0] = obj;
      } else {
        this.otherIncomeSources.push(obj);
      }
    }
    this.otherIncomeSources = JSON.parse(
      JSON.stringify(this.otherIncomeSources)
    );
  }

  public clearChildSupportData() {
    let index1 = this.otherLiabilitiesAndExpenses.findIndex(
      otherLibExp =>
        otherLibExp.name && otherLibExp.name.value == "Child Support"
    );
    let index2 = this.otherIncomeSources.findIndex(
      oIS => oIS.name && oIS.name.value == "Child Support"
    );

    if (index1 > -1) {
      this.otherLiabilitiesAndExpenses.splice(index1, 1);
    }

    if (index2 > -1) {
      this.otherIncomeSources.splice(index2, 1);
    }
    this.otherIncomeSources = JSON.parse(
      JSON.stringify(this.otherIncomeSources)
    );
    this.otherLiabilitiesAndExpenses = JSON.parse(
      JSON.stringify(this.otherLiabilitiesAndExpenses)
    );
  }

  checkPayOrReceive() {
    let index1 = this.otherLiabilitiesAndExpenses.findIndex(
      otherLibExp =>
        otherLibExp.name && otherLibExp.name.value == "Child Support"
    );
    let index2 = this.otherIncomeSources.findIndex(
      oIS => oIS.name && oIS.name.value == "Child Support"
    );
    return index1 > -1 || index2 > -1;
  }

  public get requiredValidationOnYearOrMonths() {
    let requiredValidation = true;
    if (this.personalInfo) {
      let years = this.personalInfo.borrowerInfo[this.questionHistory.index]
        .personalInfo.address.currentAddress.duration.years;
      let months = this.personalInfo.borrowerInfo[this.questionHistory.index]
        .personalInfo.address.currentAddress.duration.months;
      requiredValidation = years || months ? false : true;
    }
    return requiredValidation;
  }

  public haveFormaerAddressValidation(index){
    if(this.personalInfo){
      let years = this.personalInfo.borrowerInfo[this.questionHistory.index]
        .personalInfo.address.formerAddress[index].duration.years;
      let months = this.personalInfo.borrowerInfo[this.questionHistory.index]
        .personalInfo.address.formerAddress[index].duration.months;
        return years || months ? false : true;
    }
    return false;
  }

  public clearArmedService(checked) {
    if (!checked) {
      this.personalInfo.borrowerInfo[
        this.questionHistory.index
      ].personalInfo.militaryService.armedService.expirationDate = new DateDuration();
    }
  }

  public async getPersonalDetail() {
    try {
      this.dotLoader = true;
      let response = await Axios.get(BASE_API_URL + "pos/getPersonalDetail", {
        params: {
          loanTxnId: this.$route.query.loanTxnId
        }
      });
      this.dotLoader = false;
      this.personalInfo = response.data;
      this.personalInfo.relationshipMapping.forEach(el => {
        el.coBorrowerIndex.forEach(cb => {
          this.personalInfo.borrowerInfo[
            el.borrowerIndex
          ].personalInfo.hasAdditionalBorrower = "true";
        });
      });
    } catch (error) {
      this.dotLoader = false;
    }
  }

  handleBack() {
    let conf = this.questionHistory;
    conf.currentQuestion = 3;
    conf.previousQuestion = 2;
    conf.currentSection = "DI";
    let previousIndex = this.getPreviousIndex(conf.indexingFlow, conf.index);
    if (previousIndex) {
      if (previousIndex == conf.indexingFlow[0]) {
        conf.index = previousIndex;
        conf.userType = "borrower";
        this.$parent["activeLink"] = previousIndex;
      } else {
        conf.index = previousIndex;
        conf.userType = "coBorrower";
        this.$parent["activeLink"] = conf.index;
      }
    } else {
      conf.index = conf.indexingFlow[0];
      conf.userType = "borrower";
      this.$parent["activeLink"] = conf.indexingFlow[0];
    }
    // this.$store.dispatch("updateQuestionHistory", conf);
    this.onPrevious(
      conf.previousQuestion,
      conf.currentQuestion,
      conf.currentSection,
      true
    );
  }

  getPreviousIndex(indexingFlow, index) {
    try {
      let position = indexingFlow.indexOf(index);
      if (position > 0) {
        let previousIndex = indexingFlow[position - 1];
        return previousIndex;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  dependentsChange() {
    this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].personalInfo.dependents.number = this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].personalInfo.dependents.details.length;

    this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].personalInfo.dependents.ages = this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].personalInfo.dependents.details.map(nd => nd.age);
  }

  addFieldWithPreviousSavedData(key: string) {
    let i = this.questionHistory.index;
    switch (key) {
      case "SameAsBorrowerAddress":
        if (
          this.personalInfo.borrowerInfo[i].personalInfo
            .addressSameAsBorrower == "false"
        )
          return;
        break;
      case "SameMailingAddress":
        if (
          this.personalInfo.borrowerInfo[i].personalInfo.address
            .hasSameMailingAddress == "false"
        )
          return;
        this.personalInfo.borrowerInfo[
          i
        ].personalInfo.address.mailingAddress = this.personalInfo.borrowerInfo[
          i
        ].personalInfo.address.currentAddress.address;
        break;
    }
  }
  validateDependent() {
    let arr: Array<any> = this.personalInfo.borrowerInfo[
      this.questionHistory.index
    ].personalInfo.dependents.details;
    arr.forEach((cb, index, array) => {
      if (!(!!cb.name != null && !!cb.age)) {
        arr.splice(index, 1);
      }
    });
    if (arr.length == 0) {
      this.personalInfo.borrowerInfo[
        this.questionHistory.index
      ].personalInfo.dependents.details = [new Dependents()];
      this.personalInfo.borrowerInfo[
        this.questionHistory.index
      ].personalInfo.haveDependents = "false";
    }
  }

  get coBorrowerCurrentAddress() {
    let address = {};
    if (this.personalInfo) {
      let sameAsBorrower =
        this.personalInfo.borrowerInfo[this.questionHistory.index].personalInfo
          .addressSameAsBorrower == "true";
      if (!sameAsBorrower) {
        address = this.personalInfo.borrowerInfo[this.questionHistory.index]
          .personalInfo.address.currentAddress.address;
      } else {
        address = this.personalInfo.borrowerInfo[0].personalInfo.address
          .currentAddress.address;
      }
    }
    return this.$concatAddressFields(address);
  }

/***********************************************************************************************************************************
 *                              This function used for Add more borrower's only 
 ***********************************************************************************************************************************/
  public async saveBorrowers(ref, next, current, scope) {
    let data = await this.$refs[ref]["saveNewBorrower"]();
    if (data.status) {
      this.onNext(next, current, scope);
      await this.$emit("reLoadPosData");
      this.personalInfo.borrowerInfo = data.borrowerInfo;
      this.personalInfo.relationshipMapping=data.borrowerMapping;
    }
    this.personalInfo = JSON.parse(JSON.stringify(this.personalInfo));
  }

  public async savePersonalDetail(nextQuestion?: number, currentQuestion?: number, scope?: string, nextSection?: string, userType?:string, index?:number) {
    try {
      let valid = await this.$validator.validateAll(scope);
      let childrensValid = await this.validateChildrenComponentForms(this.$children);
      if (!valid || !childrensValid) 
        return;
      this.$store.state.wemloLoader = true;
      let res = await Axios.post(
        BASE_API_URL + "pos/savePersonalDetail",
        {
          personalInfo: this.personalInfo,
          loanTxnId: this.$route.query.loanTxnId
        });
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.onNext(nextQuestion, currentQuestion, scope, nextSection, userType, index);
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }

  /**
   * An asynchronous method to re-initialise fields of type string/object/array.
   * @param key Data property which is to be re-initialised.
   */
  public async clearFilledData(key: string) {
    const i = this.questionHistory.index;
    switch (key) {
      case "OtherNames":
        if (this.personalInfo.borrowerInfo[i].personalInfo.knownByAlternateName == "true")
          return;
          this.personalInfo.borrowerInfo[i].personalInfo.alternateNames = [new Name()];
        break;

      case "CoBorrowerOnLoan":
        /**
         * We need to confirm the borrower if he/she is sure to declare that the loan does
         * not have any co-borrowers. Once marked no, it cannot be undone.
         */
        // if (this.personalInfo.borrowerInfo.length > 1) {
        //   try {
        //     await this.$dialog.confirm(
        //       "This will remove the details of previously added co-borrowers. Are you sure you want to proceed?"
        //     );
        //   } catch (error) {
        //     this.personalInfo.borrowerInfo[
        //       this.questionHistory.borrowerIndex
        //     ].personalInfo.hasAdditionalBorrower = "true";
        //     return;
        //   }
        // } else {
        //   return;
        // }
        // this.personalInfo.borrowerInfo = this.personalInfo.borrowerInfo[0];
        // this.posDataStore.demographicInfo.borrowerInfo = this.posDataStore.demographicInfo.borrowerInfo[0];
        // this.posDataStore.acknowledgement.borrowerInfo = this.posDataStore.acknowledgement.borrowerInfo[0];
        // this.posDataStore.declaration.borrowerInfo = this.posDataStore.declaration.borrowerInfo[0];
        // this.questionHistory.pendingQuestionList.borrowerInfo = this.questionHistory.pendingQuestionList.borrowerInfo[0];
        break;

      case "HaveDependents":
        if (this.personalInfo.borrowerInfo[i].personalInfo.haveDependents == "true")
          return;
        this.personalInfo.borrowerInfo[i].personalInfo.dependents.details = [new Dependents()];
        break;

      case "CurrentRent":
        if (
          this.personalInfo.borrowerInfo[i].personalInfo.address.currentAddress
            .housing.type &&
          this.personalInfo.borrowerInfo[i].personalInfo.address.currentAddress
            .housing.type.name == "Rent"
        )
          return;
        this.personalInfo.borrowerInfo[
          i
        ].personalInfo.address.currentAddress.housing.rentPerMonth = 0;
        break;

      case "FormerRent":
        this.personalInfo.borrowerInfo[
          i
        ].personalInfo.address.formerAddress.forEach(formerAddress => {
          if (
            formerAddress.housing.type &&
            formerAddress.housing.type.name != "Rent"
          ) {
            formerAddress.housing.rentPerMonth = 0;
          }
        });
        break;

      case "SameMailingAddress":
        this.personalInfo.borrowerInfo[
          i
        ].personalInfo.address.mailingAddress = new Address();
        break;

      case "ServedInUSArmedForces":
        if (
          this.personalInfo.borrowerInfo[i].personalInfo.militaryService
            .servedOrServing == "true"
        )
          return;
        this.personalInfo.borrowerInfo[
          i
        ].personalInfo.militaryService.armedService = new AppliedService();
        break;

      case "SameAsBorrowerAddress":
        if (this.questionHistory.userType == "borrowerInfo") return;
        if (
          this.personalInfo.borrowerInfo[i].personalInfo
            .addressSameAsBorrower == "false"
        )
          return;
        this.personalInfo.borrowerInfo[
          i
        ].personalInfo.address.currentAddress.address = new Address();
        break;

      case "DurationAtCurrentAddress":
        if (this.durationAtCurrentAddress && this.durationAtCurrentAddress < 24)
          return;
        this.personalInfo.borrowerInfo[i].personalInfo.address.formerAddress = [
          new AddressTypeDetail()
        ];
        break;
    }
  }

  public updateQuestionHistory(history){
    this.questionHistory = history;
  }

  public get durationAtCurrentAddress() {
    let obj = this.personalInfo.borrowerInfo[this.questionHistory.index]
      .personalInfo.address.currentAddress.duration;
    let totalMonth = null;
    if (obj.years && obj.months) {
      totalMonth = parseInt(obj.years) * 12 + parseInt(obj.months);
    } else if (obj.years && !obj.months) {
      totalMonth = parseInt(obj.years) * 12;
    } else if (!obj.years && obj.months) {
      totalMonth = parseInt(obj.months);
    }
    return totalMonth;
  }
  async mounted() {
    this.questionHistory = this.history;
    await this.getPersonalDetail();
  }
}
